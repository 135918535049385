import { Button, Grid, Typography, Tooltip, Link } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";
import { isEmpty, isEqual } from "lodash";
import { IJobInfo } from "../../../../../../Application/DTOs/JobsDto/JobsDto.types";
import { IUserState } from "../../../../Redux/User/type";
import { JOB_STATUS, TRIP_TYPE, USER_ROLES, COMPANY_TYPES } from "../../../../Types/Constants";
import { ALL_JOBS, AVAILABLE_JOBS, DELIEVERED_JOBS, JOBS_IN_PROGRESS, MY_JOBS_IN_PROGRESS } from "../../Sidebar/Sidebar.types";
import { ALL, AVAILABLE, COMPLETED_JOBS, IN_PROGRESS as IN_PROGRESS_ST, MY_BIDS, PENDING, POSTED, BIDS_TO_CONFIRM, MY_IN_PROGRESS, BIDS, PICKED_UP } from "../Jobs.constans";
import { TShowTypes } from "../Jobs.types";
import { IColumnsCallbacks } from "./ShowJobs.types";
import { formatDateTimeDisplay, formatDateTimeDisplayDD, formatTime } from "../../../../Utilities/DateTime";

import {
    calculateGST,
    computationWaitingTimeCharge,
} from '../../../../Utilities/Computation';
import moment from "moment";
const {
    ARCHIVE,
    IN_PROGRESS,
    PICKEDUP,
    DELIVERED,
    RETURNED,
    CANCELLED,
    COMPLETED,
    STORAGE,
    VIA_YARD,
    FUMIGATION,
    CREATED, OPEN, CLONED,
    ASSIGNED,
} = JOB_STATUS
const { FREIGHT_FORWARED, IMPORT_EXPORTER, SHIPPING_LINE, TRANSPORT_COMPANY, NVOCC } = COMPANY_TYPES


const {
    ROUND_TRIP
} = TRIP_TYPE

const {
    DRIVER_MANAGER,
    COMPANY,
    DRIVER,
    COMPANY_DRIVER,
    ADMIN_USER,
    SUPER_ADMIN,
    CUSTOMER,
    WAREHOUSE
} = USER_ROLES

export const getShowJobsTableColumns = (t: TFunction, showType: TShowTypes, callbacks: IColumnsCallbacks, currentUser: IUserState): GridColumns => [

    {
        field: 'date_completed',
        headerName: t('Columns:date_completed'),
        width: 200,
        hide: COMPLETED_JOBS != showType,
        sortable: COMPLETED_JOBS != showType ? false : true,
        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            return (
                <Typography>
                    {formatDateTimeDisplayDD(job.date_completed)}
                </Typography>
            )
        }
    },
    {
        field: 'reference',
        headerName:'MI4U Ref',
        width: 150,
        sortable: ![PENDING, IN_PROGRESS].includes(showType) ? false : true,
        renderCell: (params) => {
            const job: IJobInfo = params.row as IJobInfo
            return (
                <Typography>
                    {job.reference}
                </Typography>
            )

        }

    },
    {
        field: 'postingCompanyName',
        headerName: t('company'),
        hide: ![ADMIN_USER, SUPER_ADMIN].includes(currentUser.response?.type ?? '') && COMPLETED_JOBS != showType,
        width: 300,
        sortable: [PENDING].includes(showType),
        renderCell: (params) => {
            const job: IJobInfo = params.row as IJobInfo
            return (
                <Typography>
                    {job.company?.companyName || params.row.company?.name || params.row.company_name}
                </Typography>
            )

        }
    },
    {
        field: 'customer_reference',
        headerName: t('Columns:customer_reference'),
        width: 200,
        hide: (

            [AVAILABLE, AVAILABLE_JOBS].includes(showType)
            // !([ADMIN_USER, SUPER_ADMIN].includes(currentUser.response?.type ?? ''))
            // !(([WAREHOUSE].includes(currentUser.response?.type ?? '')) && [IN_PROGRESS, COMPLETED_JOBS ].includes(showType)) && 
            // !(([COMPANY].includes(currentUser.response?.type ?? '')) && [PENDING , POSTED,MY_IN_PROGRESS,COMPLETED_JOBS , BIDS_TO_CONFIRM ].includes(showType)) && 
            // !((([COMPANY, CUSTOMER].includes(currentUser.response?.type ?? '') && ![TRANSPORT_COMPANY].includes(currentUser.response?.company?.companyType))) && [PENDING, ALL_JOBS, IN_PROGRESS, POSTED, MY_IN_PROGRESS, COMPLETED_JOBS, BIDS_TO_CONFIRM].includes(showType)) 
            // !((([COMPANY].includes(currentUser.response?.type ?? '') && [TRANSPORT_COMPANY].includes(currentUser.response?.company?.companyType))) && [IN_PROGRESS, POSTED, MY_IN_PROGRESS, COMPLETED_JOBS].includes(showType))
        ),

        renderCell: (params) => {
            const job: IJobInfo = params.row as IJobInfo
            return (
                <Typography>
                    {job.customer_reference}
                </Typography>
            )
        }
    },
    {
        field: 'transport_company',
        headerName: t('Columns:transport_company'),
        width: 300,
        sortable: false,
        hide: ![IN_PROGRESS, DELIVERED, COMPLETED, COMPLETED_JOBS].includes(showType) || ![ADMIN_USER, SUPER_ADMIN].includes(currentUser.response?.type ?? ''),
        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo

            return (
                <Typography>
                    {params.row.assigned_user?.company?.name}
                </Typography>
            )
        }
    },
    {
        field: 'pickup_city',
        headerName: t('pick_up_location'),
        sortable: false,
        width: 250,
        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            return (
                <Typography>
                    {job.pick_up_address?.company_name || params.row.pickup_address_company_name}
                </Typography>
            )
        }
    },

    {
        field: 'pickup_date_time',
        headerName: 'Scheduled Pick Up',
        sortable: ![PENDING].includes(showType) ? false : true,
        width: 250,
        hide: ![AVAILABLE, IN_PROGRESS, ALL, PENDING].includes(showType),
        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            return (
                <Typography>
                    {formatDateTimeDisplay(job.pickup_date_time)}
                </Typography>
            )
        }
    },
    {
        field: 'actual_pickup_date_time',
        headerName: 'Actual Pick Up Time',
        width: 250,
        hide: ![COMPLETED_JOBS, ALL].includes(showType),

        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            return (
                <Typography>
                    {formatDateTimeDisplay(job.actual_pick_up_time)}
                </Typography>
            )
        }
    },
    {
        field: 'delivery_city',
        headerName: t('delivery_location'),
        sortable: false,
        width: 200,
        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            return (
                <Typography>
                    {job.deliver_to_address?.company_name || params.row.delivery_address_company_name}
                </Typography>
            )
        }
    },

    {
        field: 'delivery_date',
        headerName: 'Scheduled Delivery Date',
        sortable: false,
        width: 250,
        hide: ![MY_IN_PROGRESS, ALL].includes(showType),

        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            return (
                <Typography>
                    {formatDateTimeDisplayDD(job.delivery_date_time)}
                </Typography>
            )
        }
    },
    {
        field: 'delivery_date_time',
        headerName: t('Columns:deliver_to_date_time'),
        sortable: false,
        width: 250,
        hide: ![AVAILABLE, MY_IN_PROGRESS].includes(showType),

        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            return (
                <Typography>
                    {formatDateTimeDisplay(job.delivery_date_time)}
                </Typography>
            )
        }
    },

    {
        field: 'actual_delivery_date_time',
        headerName: 'Actual Delivery Date',
        width: 250,
        hide: ![COMPLETED_JOBS, ALL].includes(showType),

        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            return (
                <Typography>
                    {formatDateTimeDisplay(job.actual_delivery_time)}
                </Typography>
            )
        }
    },
    {
        field: 'movement_type',
        headerName: t('Columns:movement_type'),
        hide: ![COMPLETED_JOBS].includes(showType) && ![ADMIN_USER, SUPER_ADMIN].includes(currentUser.response?.type ?? ''),
        sortable: false,
        width: 200,
        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            return (
                <Typography>
                    {t(job.movement_type)}
                </Typography>
            )
        }
    },
    {
        field: 'status',
        headerName: t('Columns:status'),
        sortable: false,
        width: 150,
        hide: [COMPLETED_JOBS].includes(showType),
        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            const getStatusText = (status) => {
                const assignedStatuses = ['fumigation', 'via_yard', 'storage'];
                return assignedStatuses.includes(status) ? 'assigned' : status;
            };

            return (
                <Typography>
                    {t(getStatusText(job.status))}
                </Typography>
            )
        }
    },

    {
        field: 'job_type',
        headerName: t('Columns:job_type'),
        width: 200,
        hide: [COMPLETED_JOBS, ALL].includes(showType),
        sortable: false,

        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            return (
                <Typography>
                    {t(job.job_type)}
                </Typography>
            )
        }
    },
    {
        field: 'quantity',
        headerName: t('Columns:quantity'),
        width: 200,
        hide: ![PENDING, COMPLETED_JOBS].includes(showType),
        sortable: false,

        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            return (
                <Typography>
                    {(isEmpty(job.container_no)) ? t(job.quantity) : '1'}
                </Typography>
            )


        }
    },


    {
        field: 'customer',
        headerName: t('Columns:customer'),
        width: 300,
        sortable: false,
        hide: ![ADMIN_USER, SUPER_ADMIN].includes(currentUser.response?.type ?? '') || [COMPLETED_JOBS, ALL].includes(showType),
        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo

            return (
                <Typography>
                    {job.company?.companyName || params.row.company?.name}
                </Typography>
            )

        }
    },

    {
        field: 'transport_driver',
        headerName: t('Columns:driver'),
        sortable: false,
        width: 300,
        hide: ![IN_PROGRESS, DELIVERED, COMPLETED].includes(showType) || ![ADMIN_USER, SUPER_ADMIN].includes(currentUser.response?.type ?? ''),
        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            const driver_info = job.assigned_user?.["user_type"] || null
            let fname = ''
            let lname = ''

            if (driver_info && driver_info['id'] == 7) {
                fname = job.assigned_user['first_name']
                lname = job.assigned_user['last_name']
            }
            return (
                <Typography>
                    {driver_info ? `${fname} ${lname}` : ''}
                </Typography>
            )

        }
    },
    {
        field: 'job_bid.amount',
        headerName: t('Columns:bid_price'),
        width: 200,
        hide: ![MY_BIDS].includes(showType),
        valueGetter: (params) => {
            const jobInfo = params.row as IJobInfo

            if (jobInfo.job_bids && jobInfo.job_bids.length > 0 && jobInfo.job_bids[0].amount) {
                return jobInfo.job_bids[0].amount
            } else {
                return ''
            }
        }
    },
    {
        field: 'job_bid.waitTime',
        headerName: t('Columns:wait_time'),
        sortable: false,
        width: 200,
        hide: ![MY_BIDS].includes(showType),
        valueGetter: (params) => {
            const jobInfo = params.row as IJobInfo

            if (jobInfo.job_bids && jobInfo.job_bids.length > 0 && jobInfo.job_bids[0].waitTime) {
                return jobInfo.job_bids[0].waitTime
            } else {
                return 0
            }
        }
    },
    {
        field: 'job_bid.waitTimeCharge',
        headerName: t('Columns:wait_time_charge'),
        sortable: false,
        width: 200,
        hide: [BIDS_TO_CONFIRM, AVAILABLE, PENDING, COMPLETED_JOBS, ALL].includes(showType),
        valueGetter: (params) => {
            const jobInfo = params.row as IJobInfo

            // if (params.row.job_bids && params.row.job_bids.length > 0 && params.row.job_bids[0].wait_time_charge) {
            //     return params.row.job_bids[0].wait_time_charge
            // } else {
            return jobInfo.job_wait_time_charge || 0
            // }
        }
    },

    {
        field: 'posted_price',
        sortable: false,
        headerName: t('Columns:posted_price'),
        width: 200,
        hide: ![MY_BIDS].includes(showType),
    },


    {
        field: 'bids_count',
        headerName: t('Columns:bids'),
        hide: ![POSTED, BIDS_TO_CONFIRM, BIDS].includes(showType),
        sortable: false,
        width: 100,
        valueGetter: (params) => {
            const jobInfo = params.row as IJobInfo

            return jobInfo.job_bids?.length ?? 0
        }

    },
    {
        field: 'make_public',
        headerName: t('Columns:make_public'),
        width: 200,
        sortable: false,
        hide: ![PENDING].includes(showType),
        renderCell: (params) => <Button onClick={() => callbacks.onMakePublic(params.row)} variant="contained" color="primary">{t('make_public')}</Button>
    },
    {
        field: 'set_actual_date',
        headerName: t('update_job'),
        width: 200,
        hide: [ALL_JOBS].includes(showType) || ![DRIVER_MANAGER, COMPANY_DRIVER, DRIVER, COMPANY, ADMIN_USER, SUPER_ADMIN].includes(currentUser.response?.type ?? ''),

        // hide: ![IN_PROGRESS_ST,MY_IN_PROGRESS ,ALL_JOBS].includes(showType),
        sortable: false,
        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            let buttonLabel = ''
            let statusLabel = ''
            switch (job.status) {
                case ASSIGNED:
                    buttonLabel = 'set_pick_up_date'
                    statusLabel = 'awaiting_pickup'
                    break;
                case PICKEDUP:
                case FUMIGATION:
                case VIA_YARD:
                case STORAGE:
                    buttonLabel = 'set_delivery_date'
                    statusLabel = 'picked_up'
                    break;
                case DELIVERED:
                    if (job.trip_type === ROUND_TRIP) {
                        buttonLabel = 'set_return_date'
                    } else {
                        buttonLabel = 'complete_job'
                    }
                    statusLabel = 'delivered'
                    break;
                case RETURNED:
                    statusLabel = 'returned'
                    buttonLabel = 'complete_job'
                    break;
                default:
                    statusLabel = job.status.toUpperCase()
            }
            return (
                !isEqual(job?.company?.id, currentUser.response?.company?.id) && ![ARCHIVE, CREATED, COMPLETED, CANCELLED].includes(job.status) ?
                    (!!job.additional_step_status &&
                        job.status === PICKEDUP &&
                        (!job.additional_step_end_date || moment().isSameOrBefore(job.additional_step_end_date))) ?
                        <Button fullWidth onClick={(event: any) => {
                            event.stopPropagation();
                            callbacks.onAdditionalStep(job)
                        }} variant="contained" color="primary">
                            {(!job.additional_step_status) ?
                                t('Columns:none')
                                :
                                t(job.additional_step_status)
                            } </Button>
                        :
                        <Button fullWidth size="medium" onClick={(event: any) => {
                            event.stopPropagation();

                            if (buttonLabel === 'complete_job') {
                                callbacks.onMarkAsComplete(job)
                            } else {
                                callbacks.onSetDate(job)
                            }

                        }} variant="contained" color="primary">
                            {t(buttonLabel)}
                        </Button>


                    : <Typography>{t(statusLabel)}</Typography>
            )


        }
    },

    // {
    //     field: 'edit_actual_dates',
    //     headerName: t('Columns:edit_actual_dates'),
    //     hide: ![MY_IN_PROGRESS, IN_PROGRESS_ST].includes(showType),
    //     width: 200,
    //     sortable: false,
    //     disableClickEventBubbling: true,
    //     renderCell: (params) => <Button onClick={() => callbacks.onEditActualDatesForJob(params.row as IJobInfo)} variant="contained" color="primary">{t('edit_actual_dates')}</Button>,
    // },
    {
        field: 'assign_driver',
        headerName: t('Columns:assigned_driver'),
        width: 500,
        hide: ![IN_PROGRESS_ST].includes(showType) || ![COMPANY].includes(currentUser.response?.type ?? ''),
        sortable: false,
        // disableClickEventBubbling: true,
        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            const jobdata = params.row
            // const { id, assigned_user } = job
            const { id, assigned_user } = jobdata

            if (assigned_user && assigned_user.id && (assigned_user.user_type.id == 2 || assigned_user.user_type.id == 7)) {
                return (
                    <Typography>
                        {`${assigned_user.first_name} ${assigned_user.last_name}`}
                    </Typography>
                )
            }

            return (
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item md={6}>
                        <Button onClick={(event: any) => {
                            event.stopPropagation()
                            callbacks.onSelectDriverClick(true, false, id)
                        }
                        }
                            fullWidth variant="contained" color="primary">
                            {t('set_driver_manager')}
                        </Button>
                    </Grid>
                    <Grid item md={6}>
                        <Button onClick={(event: any) => {
                            event.stopPropagation()

                            callbacks.onSelectDriverClick(false, true, id)
                        }
                        }
                            fullWidth variant="contained">
                            {t('set_driver')}
                        </Button>
                    </Grid>
                </Grid>
            )
        }
    },
    {
        field: 'tracking',
        headerName: "Tracking",
        width: 150,
        hide: ![IN_PROGRESS_ST].includes(showType) || ![COMPANY].includes(currentUser.response?.type ?? ''),
        sortable: false,
        // disableClickEventBubbling: true,
        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            const jobdata = params.row
            const { id, assigned_user } = jobdata


            if (assigned_user && assigned_user.user_type && (assigned_user.user_type.id == 2 || assigned_user.user_type.id == 7)) {
                return (
                    <Button onClick={(event: any) => {
                        event.stopPropagation()
                        callbacks.onTrackUser(job)
                    }} variant='contained' color='primary'>Track Driver</Button>
                )
            }
            return (<Grid></Grid>)

        }
    },
    {
        field: 'label_connote_action',
        headerName: "Labels/Connote",
        width: 150,
        // hide: !((([COMPANY].includes(currentUser.response?.type ?? '') && [TRANSPORT_COMPANY].includes(currentUser.response?.company?.companyType))) && [IN_PROGRESS, MY_IN_PROGRESS, COMPLETED_JOBS].includes(showType)),
        hide: [COMPLETED_JOBS, ALL].includes(showType) || ![ALL].includes(showType) && currentUser.response?.company.companyType == 'transport_company',
        sortable: false,
        // disableClickEventBubbling: true,
        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo
            const jobdata = params.row
            const { id, assigned_user } = jobdata

            // if (assigned_user && assigned_user.user_type && (assigned_user.user_type.id == 2 || assigned_user.user_type.id == 7)) {
            return (
                <Button onClick={(event: any) => {
                    callbacks.onConnoteClick(job)
                }} variant='contained' color='primary'>Attach</Button>
            )
            // }
            // return (<Grid></Grid>)

        }
    },
    {
        field: 'job_bid.status',
        headerName: t('Columns:bid_status'),
        width: 200,
        hide: ![MY_BIDS].includes(showType),
        valueGetter: (params) => {
            const jobInfo = params.row as IJobInfo

            if (jobInfo.job_bids && jobInfo.job_bids.length > 0 && jobInfo.job_bids[0].status) {
                return t(jobInfo.job_bids[0].status)
            } else {
                return ''
            }
        }
    },


    {
        field: 'actions',
        headerName: t('Columns:actions'),
        width: 200,
        hide: ![DELIEVERED_JOBS].includes(showType),
        sortable: false,
        // disableClickEventBubbling: true,
        renderCell: (params) => <Button onClick={(event: any) => {
            event.stopPropagation()
            callbacks.onMarkAsComplete(params.row)
        }
        }
            variant="contained" color="primary">{t('Columns:mark_as_complete')}</Button>,
    },
    {
        field: 'bid',
        headerName: t('Columns:bid'),
        width: 200,
        hide: ![AVAILABLE].includes(showType) || (currentUser.response?.company?.companyType == 'import_exporter') || (currentUser.response?.type === CUSTOMER),
        sortable: false,
        renderCell: (params) => <Button onClick={(event: any) => {
            event.stopPropagation()
            callbacks.onBid(params.row)
        }} variant="contained" color="primary">{t('Columns:bid')}</Button>,
        // disableClickEventBubbling: true
    },

    {
        field: 'editJob',
        headerName: t('Columns:edit_job'),
        width: 200,
        hide: ![BIDS_TO_CONFIRM].includes(showType),
        sortable: false,
        renderCell: (params) => <Button onClick={(event: any) => {
            event.stopPropagation()
            callbacks.onEditJob(params.row as IJobInfo)
        }}
            variant="contained" color="primary">{t('Columns:edit_job')}</Button>,
        // disableClickEventBubbling: true
    },
    // {
    //     field: 'additional_step',
    //     headerName: t('Columns:additional_step'),
    //     hide: [AVAILABLE].includes(showType) || ![ADMIN_USER, SUPER_ADMIN, COMPANY].includes(currentUser.response?.type ?? ''),
    //     width: 200,
    //     disableClickEventBubbling: true,
    //     sortable: false,
    //     renderCell: (params) => {
    //         let job: IJobInfo = params.row as IJobInfo
    //         let tooltext = ''
    //         if(job.additional_step_status != null && job.additional_step_status != ''){
    //            tooltext = `In ${t(job.additional_step_status)} from ${formatDateTimeDisplay(job.additional_step_date)} to ${formatDateTimeDisplay(job.additional_step_end_date)}`
    //         }
    //         return [PICKEDUP].includes(job.status) 
    //             ? ((job.additional_step_status == null || job.additional_step_status == "") ?
    //                 <Button fullWidth onClick={() => callbacks.onAdditionalStep(job)} variant="contained" color="primary">{t('Columns:none')}</Button>
    //             :  <Button fullWidth onClick={() => callbacks.onAdditionalStep(job)} variant="contained" color="primary">{t(job.additional_step_status)} </Button>)
    //             // <Grid>
    //             //      <Tooltip title={tooltext} >
    //             //         <Button onClick={() => callbacks.onAdditionalStep(job)}>   In { t(job.additional_step_status)} 
    //             //         </Button>
    //             // <span className="table-cell-trucate">In { t(job.additional_step_status)} </span>
    //             // </Tooltip>
    //             // </Grid>)
    //             : <Grid/>

    //             // : <Button onClick={() => callbacks.onCancelJob(job)} variant="contained" color="secondary">{t('Columns:cancel_job')}</Button>
    //     }
    // },
    {
        field: 'cancel',
        headerName: t('Columns:cancel_job'),
        hide: [COMPLETED_JOBS, AVAILABLE, MY_BIDS, IN_PROGRESS, MY_JOBS_IN_PROGRESS].includes(showType) || ![ADMIN_USER, SUPER_ADMIN, COMPANY].includes(currentUser.response?.type ?? ''),
        width: 200,
        // disableClickEventBubbling: true,
        sortable: false,
        renderCell: (params) => {
            let job: IJobInfo = params.row as IJobInfo

            return [CANCELLED, PICKEDUP, DELIVERED, RETURNED, COMPLETED, STORAGE, VIA_YARD, FUMIGATION].includes(job.status) || (isEqual(job.company_id, currentUser.response?.company?.id) && currentUser.response?.type === COMPANY)
                ? <Typography>{t('cannot_cancel')}</Typography>
                : <Button onClick={(event: any) => {
                    event.stopPropagation()
                    callbacks.onCancelJob(job)
                }}
                    variant="contained" color="secondary">{t('Columns:cancel_job')}</Button>
        }
    },
    {
        field: 'pod',
        headerName: t('Columns:download_pod'),
        width: 200,
        hide: ![COMPLETED_JOBS, ALL].includes(showType),
        sortable: false,
        renderCell: (params) => {
            const job: IJobInfo = params.row as IJobInfo
            if (job.status == COMPLETED) {
                return (
                    <Button onClick={(event: any) => {
                        event.stopPropagation()
                        callbacks.onDownloadPod(params.row)
                    }
                    } variant="contained" color="primary" > {t('Columns:download_pod')}</Button >)
            } else {
                return <Typography>{'POD not available'}</Typography>
            }

        }
        // disableClickEventBubbling: true
    },
]